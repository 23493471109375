@use '../base/vars' as *;

.page-vendingMachine {
  font-family: 游ゴシック体, YuGothic, 游ゴシック Medium, Yu Gothic Medium, 游ゴシック, Yu Gothic, sans-serif;
  @include rem(16);
  color: #073190;
  font-weight: 500;
  line-height: 1.65;

  @include media_query(720) {
    @include rem(14);
  }

  .kvContainer {
    position: relative;

    &__titlewrap {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      box-shadow: 4px 4px 0px 0px #073190;
      width: 588px;
      height: 13vw;
      max-height: 180px;

      @include media_query(1000) {
        height: 120px;
      }

      @include media_query(799) {
        height: 160px;
      }

      @include media_query(720) {
        width: 335px;
        top: 95%;
      }

      .pc-none {
        display: none;

        @include media_query(799) {
          display: inline;
        }
      }
      .sp-none {

        @include media_query(799) {
          display: none;
        }
      }
    }

    &__title {
      @include rem(26);
      font-weight: bold;
      text-align: center;

      @include media_query(1000) {
        @include rem(22);
      }

      &--en {
        @include rem(30);
        font-weight: normal;
        display: block;
        font-family: Dancing Script, cursive;

        @include media_query(1000) {
          @include rem(19);
        }
      }
    }

  }

  .bg-container {
      padding-top: 90px;
      @include media_query(1000) {
        padding-top: 160px;
    }
  }

  .instructionsText {
    color: #fff;
    text-align: center;
    letter-spacing: 0.15em;
    padding-top: 69px;
    
    @include media_query(720) {
      padding-top: 80px;
      line-height: 1.85;
    }
  }


  //自販機のある場所
  .mapContainer {
    background-color: #fff;
    border-radius: 20px;
    padding: 70px 50px 106px;
    margin-top: 80px;
    margin-bottom: 100px;

    @include media_query(720) {
      border-radius: 12px;
      padding: 40px 16px 46px;
      margin-top: 35px;
      margin-bottom: 15px;
    }

    &__title {
      @include rem(26);
      font-weight: bold;
      max-width: 700px;
      margin: 0 auto;
      padding: 9px 0;
      background-color: #fff;
      border: 1px solid #073190;
      box-shadow: 4px 4px 0px 0px #073190;
      text-align: center;

      @include media_query(720) {
        @include rem(18);
        box-shadow: 2px 2px 0px 0px #073190;
        padding: 15px 0;
      }
    }
  }

  .mapLinks {

    &__lists {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 468px;
      margin: 0 auto;
      padding-top: 62px;

      @include media_query(720) {
        padding-top: 30px;
      }
    }

    &__link {
      position: relative;
      padding: 0 8px 6px;
      transition: opacity .3s;
      font-weight: bold;

      @include media_query(720) {
        padding: 0 5px 6px;
      }

      &:hover {
        opacity: .7;
      }

      &::before,
      &::after {
        content: '';
        display: block;
        background-color: #073190;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      &::before {
        width: 10px;
        height: 5px;
        clip-path: polygon(0 0, 50% 100%, 100% 0);
        top: 100%;
      }

      &::after {
        width: 100%;
        height: 1px;
        bottom: 0;
      }
    }
  }


  .mapListsWrap {

    &__lists {
      margin-top: -13px;

      @include media_query(800) {
        margin-top: -16px;
      }
    }

    &__item {
      display: flex;
      justify-content: space-between;
      padding-top: 98px;

      @include media_query(800) {
        flex-direction: column;
        padding-top: 64px;
      }
    }

    .contentsBox {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: percentage(250px / 900px);

      @include media_query (800) {
        width: 100%;
      }

      &__textWrap {
        letter-spacing: normal;
      }

      &__title {
        @include rem(20);
        font-weight: bold;
        padding: 12px 0 10px;
        border-top: 1px dotted #073190;
        border-bottom: 1px dotted #073190;

        @include media_query(720) {
          @include rem(16);
          padding: 8px 0;
        }
      }

      &__text {
        @include rem(15);
        padding: 12px 0;
      }
    }
    
    .contentsBox__text_alert{
        margin-top: 20px;
        @include rem(18);
        font-weight: bold;
        color: red;
    }

    .googleMapWrap {
      width: percentage(600px / 900px);

      @include media_query (800) {
        width: 100%;
        padding-top: 16px;
      }

      &__inner {
        position: relative;
        width: 100%;
        padding-top: percentage(350px / 600px);

        @include media_query (800) {
          padding-top: percentage(177px / 304px);
        }
      }

      .googleMap {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
      }
    }

  }
  
}
