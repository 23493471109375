@use '../base/vars' as *;
.bg-container {
    &.news {
        padding-bottom: 300px;
        @include media_query( 760 ){
            padding-bottom: 154px;
        }    
    }
}
.top-news {
    padding-top: 100px;
    color: #fff;
    letter-spacing: 0.1em;
    @include media_query( 720 ){
        padding-top: 20px;
    }

    &-heading {
        text-align: center;
        position: relative;
        padding-bottom: 24px;
        @include media_query( 720 ){
            padding-bottom: 20px;
        }
        &::before {
            content: "";
            display: inline-block;
            background-image: url(../img/top/temporary-closed.svg);
            background-repeat: no-repeat;
            background-size: contain;
            width: 736px;
            height: 96px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            @include media_query( 760 ){
                width: 335px;
                height: 44px;    
            }
        }

        &--date {
            display: inline-block;
            @include rem(20);
            font-weight: bold;
            letter-spacing: 0.1em;
            position: relative;
            padding: 0 30px;

            &::before, &::after {
                position: absolute;
                top: 50%;
                content: "";
                display: inline-block;
                background-image: url(../img/top/line.svg);
                background-repeat: no-repeat;
                background-size: contain;
                width: 15px;
                height: 20px;
            }

            &::before {
                transform: translateY(-50%);
                left: 0;
            }

            &::after {
                transform: translateY(-50%) scale(-1, 1);
                right: 0;
            }

        }

        &--text {
            @include rem(26);
            font-weight: bold;
            line-height: 1.15;
            padding-top: 20px;
            position: relative;
            z-index: 1;
            @include media_query( 720 ){
                @include rem(24);
                padding-top: 12px;
            }
        }

    }
    &-text {
        @include rem(16);
        text-align: center;
        padding-top: 16px;
        @include media_query( 720 ){
            @include rem(13);
        }
        p {
            line-height: 1.8;
            padding-bottom: 1em;
            font-weight: 500;
            &:not(:nth-of-type(3)) {
                padding-bottom: 0;
                @include media_query( 720 ){
                    padding-bottom: 1em;
                }
            }
        }
        &.-text02 {
            padding-top: 32px;
        }
    }

    &-list {
        @include rem(16);
        @include media_query( 800 ){
            @include rem(14);
        }
        &--frame {
            background-image: url(../img/top/frame.svg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: left;
            width: 740px;
            min-height: 254px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;

            @include media_query( 800 ){
                background-image: url(../img/top/sp_frame.svg);
                width: 335px;
                min-height: 246px;
                justify-content: flex-start;
            }

            &--wrap {
                padding-top: 20px;

                @include media_query( 720 ){
                    padding-top: 0;
                }

            }
        }

        &--title {
            padding-top: 32px;
            @include rem(16);
            text-align: center;
            @include media_query( 800 ){
                @include rem(14);
            }
        }

        &--wrap {
            padding-top: 24px;
            padding-bottom: 28px;
            padding-left: 30px;
            padding-right: 30px;
            @include media_query( 800 ){
                padding-top: 20px; 
            }
        }

        &--item {
            position: relative;
            padding-left: 30px;
            margin-top: 12px;
            line-height: 1.7;
            @include media_query( 800 ){
                margin-top: 4px;
            }
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                display: inline-block;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: left;
                margin-top: 4px;
                @include media_query( 800 ){
                    margin-top: 0;
                }    
            }

            &:nth-of-type(1) {
                &::before {
                    background-image: url(../img/top/one.svg);
                    width: 18px;
                    height: 16px;
                }
            }

            &:nth-of-type(2) {
                &::before {
                    background-image: url(../img/top/two.svg);
                    width: 19px;
                    height: 16px;    
                }
            }

            &:nth-of-type(3) {
                &::before {
                    background-image: url(../img/top/three.svg);
                    width: 19px;
                    height: 16px;    
                }
            }

        }

    }

    .accent {
        font-weight: bold;
        color: $accent-color;
    }

    .pc-none {
        display: none;
        @include media_query( 760 ){
            display: block;
        }
    }

}

.top-list {
    &.--flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

.banner {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
}

// 詳細ページのみ上部に余白
.single .banner{
    margin-top: 60px;
}

.online-cont .heading-line-white{
    margin-top: 60px;
}

.best-3{
    margin-top: 60px;
}

.gnav__link {
    span {
        @include rem(22);
        @include media_query( 1070 ){
            @include rem(16);
        }
    }
    .txt-register {
        @include rem(15);
        font-weight: 600;
        @include media_query( 1070 ){
            @include rem(14);
        }
    }
}

.note {
    text-align: center;
    padding: 20px 25px 0;
    .note_txt {
        display: inline-block;
        text-align: left;
        @include rem(16);
        font-weight: bold;
        color: $note-color;
        letter-spacing: 0.05em;	
        @include media_query( 720 ){
            @include rem(14);
        }
    }
}

.top-link--txtwrap {
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: rgba($color: #070D1A, $alpha: 0.65);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    .top-list--txt {
        font-family: Huifont, sans-serif;
        color: white;
        position: relative;
        @include rem(16);
        padding-right: 30px;
        margin-bottom: 8px;
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            display: inline-block;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: left;
            margin-top: 4px;
            background-image: url(../img/top/top-link/arrow.svg);
            width: 121px;
            height: 9px;
        }
    }
}

.store_info {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    position: relative;
    background-color: white;
    padding-bottom: 121px;
    @include media_query( 760 ){
        padding-bottom: 55px;
    }

    .bg-container {
        max-width: 1040px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .store_info--img {
        margin-top: -230px;
        @include media_query( 760 ){
            margin-top: -164px;
        }        
    }

    .store_info--ttl {
        @include rem(30);
        font-family: tornac, sans-serif;
        text-align: center;
        padding-top: 46px;
        @include media_query( 760 ){
            @include rem(24);
            padding-bottom: 12px;
        }    
    }
    .store_info--list-wrap {
        text-align: center;
        @include media_query( 375 ){
            text-align: left;
        }
        .store_info--list {
            display: inline-block;
            font-family: 游ゴシック体, YuGothic, 游ゴシック Medium, Yu Gothic Medium, 游ゴシック, Yu Gothic, sans-serif;
            @include rem(18);
            @include media_query( 760 ){
            }

            .store_info--item {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                padding-top: 36px;
                @include media_query( 760 ){
                    display: block;
                    padding-top: 24px;
                }
            
                &.sns_icon {
                    padding-top: 12px;
                    @include media_query( 760 ){
                        padding-top: 0;
                    }
                
                }
                
                .store_info--list-dt {
                    text-align: right;
                    width: 4.5em;
                    @include media_query( 760 ){
                        width: auto;
                        text-align: left;
                        font-weight: bold;
                    }
                
                }
                .store_info--list-dd {
                    text-align: left;
                    padding-left: 60px;
                    width: calc(100% - 4.5em);
                    @include media_query( 760 ){
                        padding-left: 22px;
                        padding-top: 12px;
                        width: auto;
                        font-size: 15px;
                    }
                
                }
                .store_info--sns {
                    display: flex;
                    column-gap: 10px;
                    @include media_query( 760 ){
                    }
                
                    .store_info--sns_icon {
                        @include media_query( 760 ){
                        }
                    
                    }

                }
            }
        }    
    }

    .store_info--banner {
        display: block;
        width: 100%;
        max-width: 790px;
        margin: 68px auto 0;
        box-shadow: 0px 0px 19px -8px #6f7579;
        overflow: hidden;
        @include media_query( 760 ){
        }
    
    }
    a:hover {
        opacity: 0.7;
        transition: opacity .3s ease-in-out;
    }
}







.movie{
    margin: 80px 0;
    
    .movie_inner{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 50px;
        max-width: 800px;
        
        @include media_query(){
            grid-template-columns: 1fr;
            gap: 30px;
        }
        
        .movie_title{
            color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            
            h2{
                font-family: tornac, sans-serif;
                @include rem(50);
                
                @include media_query(){
                    @include rem(30);
                }
            }
            
            p{
                margin-top: 25px;
                @include rem(20);
                text-align: center;
                line-height: 1.8;
                
                @include rem(18);
            }
        }
        .movie_main{
            video{
                width: 100%;
                height: auto;
            }
        }
    }
}





.banner_card_inner{
    margin-top: 30px;
    
    a{
        &:hover{
            opacity: .7;
        }
    }
}

.online-price {
    & p._source {
        text-decoration: none;

        & .--price {
            text-decoration: line-through;
        }

        & .online-tax {
            text-decoration: line-through;
        }

    }
    
    .saleoff{
        color: #f00;
    }
}

.product-main__price {
    &._sale {
        & .--line-through {
            text-decoration: line-through;
        }
        & .in-tax.--line-through {
            text-decoration: line-through;
        }
    }
}

.top-banner {
    > a {
        transition: 0.3s;
        &:hover {
            opacity: 0.7;
        }
    }
}

.menu-online{
    position: relative;
    
    .boximg{
        position: absolute;
        right: 20px;
        top: 30px;
        width: 200px;
        height: auto;
        border-radius: 15px;
        rotate: 15deg;
        
        @include media_query(){
            right: 10px;
            top: 10px;
            width: 130px;
        }
    }
}

.online-box_item{
    padding-top: 10px !important;
    padding-bottom: 20px !important;
}

.online-box_item.sbox{
    // border: solid 3px #073190;
    background-color: rgba(#fabf00, .2);
    border-radius: 5px;
}
