@charset "UTF-8";
.d-block {
  display: block;
}

.popup {
  background-color: #FABF1C;
  position: fixed;
  z-index: 99;
  writing-mode: vertical-rl;
  font-size: 16px;
  font-weight: bold;
  color: #073191;
  padding: 5px;
  bottom: 0;
  right: 0;
}
@media screen and (max-width: 760px) {
  .popup {
    width: 100%;
    font-size: 13px;
    writing-mode: horizontal-tb;
  }
}
.popup span {
  display: flex;
  padding: 10px;
  border: dashed 1px #fff;
  line-height: 1;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 760px) {
  .popup span {
    display: grid;
    grid-template-columns: 1fr 20px;
    align-items: center;
    padding: 5px 10px;
  }
}
.popup span i {
  writing-mode: horizontal-tb;
  width: 18px;
  height: 18px;
  display: grid;
  place-items: center;
  background-color: #fff;
  border-radius: 100%;
  margin-bottom: 5px;
}
@media screen and (max-width: 760px) {
  .popup span i {
    margin-top: 5px;
    order: 1;
  }
}
.popup span i svg {
  margin-right: -2px;
}
.popup span i .svg-icon {
  width: 15px;
  height: 15px;
}
.popup span i .svg-icon path,
.popup span i .svg-icon polygon,
.popup span i .svg-icon rect {
  fill: #000;
}

@media screen and (max-width: 760px) {
  .footer {
    padding-bottom: 26.7813267813%;
  }
}

.bg-container.news {
  padding-bottom: 300px;
}
@media screen and (max-width: 760px) {
  .bg-container.news {
    padding-bottom: 154px;
  }
}

.top-news {
  padding-top: 100px;
  color: #fff;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 720px) {
  .top-news {
    padding-top: 20px;
  }
}
.top-news-heading {
  text-align: center;
  position: relative;
  padding-bottom: 24px;
}
@media screen and (max-width: 720px) {
  .top-news-heading {
    padding-bottom: 20px;
  }
}
.top-news-heading::before {
  content: "";
  display: inline-block;
  background-image: url(../img/top/temporary-closed.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 736px;
  height: 96px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}
@media screen and (max-width: 760px) {
  .top-news-heading::before {
    width: 335px;
    height: 44px;
  }
}
.top-news-heading--date {
  display: inline-block;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.1em;
  position: relative;
  padding: 0 30px;
}
.top-news-heading--date::before, .top-news-heading--date::after {
  position: absolute;
  top: 50%;
  content: "";
  display: inline-block;
  background-image: url(../img/top/line.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 15px;
  height: 20px;
}
.top-news-heading--date::before {
  transform: translateY(-50%);
  left: 0;
}
.top-news-heading--date::after {
  transform: translateY(-50%) scale(-1, 1);
  right: 0;
}
.top-news-heading--text {
  font-size: 2.6rem;
  font-weight: bold;
  line-height: 1.15;
  padding-top: 20px;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 720px) {
  .top-news-heading--text {
    font-size: 2.4rem;
    padding-top: 12px;
  }
}
.top-news-text {
  font-size: 1.6rem;
  text-align: center;
  padding-top: 16px;
}
@media screen and (max-width: 720px) {
  .top-news-text {
    font-size: 1.3rem;
  }
}
.top-news-text p {
  line-height: 1.8;
  padding-bottom: 1em;
  font-weight: 500;
}
.top-news-text p:not(:nth-of-type(3)) {
  padding-bottom: 0;
}
@media screen and (max-width: 720px) {
  .top-news-text p:not(:nth-of-type(3)) {
    padding-bottom: 1em;
  }
}
.top-news-text.-text02 {
  padding-top: 32px;
}
.top-news-list {
  font-size: 1.6rem;
}
@media screen and (max-width: 800px) {
  .top-news-list {
    font-size: 1.4rem;
  }
}
.top-news-list--frame {
  background-image: url(../img/top/frame.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  width: 740px;
  min-height: 254px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
@media screen and (max-width: 800px) {
  .top-news-list--frame {
    background-image: url(../img/top/sp_frame.svg);
    width: 335px;
    min-height: 246px;
    justify-content: flex-start;
  }
}
.top-news-list--frame--wrap {
  padding-top: 20px;
}
@media screen and (max-width: 720px) {
  .top-news-list--frame--wrap {
    padding-top: 0;
  }
}
.top-news-list--title {
  padding-top: 32px;
  font-size: 1.6rem;
  text-align: center;
}
@media screen and (max-width: 800px) {
  .top-news-list--title {
    font-size: 1.4rem;
  }
}
.top-news-list--wrap {
  padding-top: 24px;
  padding-bottom: 28px;
  padding-left: 30px;
  padding-right: 30px;
}
@media screen and (max-width: 800px) {
  .top-news-list--wrap {
    padding-top: 20px;
  }
}
.top-news-list--item {
  position: relative;
  padding-left: 30px;
  margin-top: 12px;
  line-height: 1.7;
}
@media screen and (max-width: 800px) {
  .top-news-list--item {
    margin-top: 4px;
  }
}
.top-news-list--item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  margin-top: 4px;
}
@media screen and (max-width: 800px) {
  .top-news-list--item::before {
    margin-top: 0;
  }
}
.top-news-list--item:nth-of-type(1)::before {
  background-image: url(../img/top/one.svg);
  width: 18px;
  height: 16px;
}
.top-news-list--item:nth-of-type(2)::before {
  background-image: url(../img/top/two.svg);
  width: 19px;
  height: 16px;
}
.top-news-list--item:nth-of-type(3)::before {
  background-image: url(../img/top/three.svg);
  width: 19px;
  height: 16px;
}
.top-news .accent {
  font-weight: bold;
  color: #FABF00;
}
.top-news .pc-none {
  display: none;
}
@media screen and (max-width: 760px) {
  .top-news .pc-none {
    display: block;
  }
}

.top-list.--flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.banner {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}

.single .banner {
  margin-top: 60px;
}

.online-cont .heading-line-white {
  margin-top: 60px;
}

.best-3 {
  margin-top: 60px;
}

.gnav__link span {
  font-size: 2.2rem;
}
@media screen and (max-width: 1070px) {
  .gnav__link span {
    font-size: 1.6rem;
  }
}
.gnav__link .txt-register {
  font-size: 1.5rem;
  font-weight: 600;
}
@media screen and (max-width: 1070px) {
  .gnav__link .txt-register {
    font-size: 1.4rem;
  }
}

.note {
  text-align: center;
  padding: 20px 25px 0;
}
.note .note_txt {
  display: inline-block;
  text-align: left;
  font-size: 1.6rem;
  font-weight: bold;
  color: #D12B2B;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 720px) {
  .note .note_txt {
    font-size: 1.4rem;
  }
}

.top-link--txtwrap {
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: rgba(7, 13, 26, 0.65);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
}
.top-link--txtwrap .top-list--txt {
  font-family: Huifont, sans-serif;
  color: white;
  position: relative;
  font-size: 1.6rem;
  padding-right: 30px;
  margin-bottom: 8px;
}
.top-link--txtwrap .top-list--txt::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  margin-top: 4px;
  background-image: url(../img/top/top-link/arrow.svg);
  width: 121px;
  height: 9px;
}

.store_info {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  position: relative;
  background-color: white;
  padding-bottom: 121px;
}
@media screen and (max-width: 760px) {
  .store_info {
    padding-bottom: 55px;
  }
}
.store_info .bg-container {
  max-width: 1040px;
  padding-left: 20px;
  padding-right: 20px;
}
.store_info .store_info--img {
  margin-top: -230px;
}
@media screen and (max-width: 760px) {
  .store_info .store_info--img {
    margin-top: -164px;
  }
}
.store_info .store_info--ttl {
  font-size: 3rem;
  font-family: tornac, sans-serif;
  text-align: center;
  padding-top: 46px;
}
@media screen and (max-width: 760px) {
  .store_info .store_info--ttl {
    font-size: 2.4rem;
    padding-bottom: 12px;
  }
}
.store_info .store_info--list-wrap {
  text-align: center;
}
@media screen and (max-width: 375px) {
  .store_info .store_info--list-wrap {
    text-align: left;
  }
}
.store_info .store_info--list-wrap .store_info--list {
  display: inline-block;
  font-family: 游ゴシック体, YuGothic, 游ゴシック Medium, Yu Gothic Medium, 游ゴシック, Yu Gothic, sans-serif;
  font-size: 1.8rem;
}
.store_info .store_info--list-wrap .store_info--list .store_info--item {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-top: 36px;
}
@media screen and (max-width: 760px) {
  .store_info .store_info--list-wrap .store_info--list .store_info--item {
    display: block;
    padding-top: 24px;
  }
}
.store_info .store_info--list-wrap .store_info--list .store_info--item.sns_icon {
  padding-top: 12px;
}
@media screen and (max-width: 760px) {
  .store_info .store_info--list-wrap .store_info--list .store_info--item.sns_icon {
    padding-top: 0;
  }
}
.store_info .store_info--list-wrap .store_info--list .store_info--item .store_info--list-dt {
  text-align: right;
  width: 4.5em;
}
@media screen and (max-width: 760px) {
  .store_info .store_info--list-wrap .store_info--list .store_info--item .store_info--list-dt {
    width: auto;
    text-align: left;
    font-weight: bold;
  }
}
.store_info .store_info--list-wrap .store_info--list .store_info--item .store_info--list-dd {
  text-align: left;
  padding-left: 60px;
  width: calc(100% - 4.5em);
}
@media screen and (max-width: 760px) {
  .store_info .store_info--list-wrap .store_info--list .store_info--item .store_info--list-dd {
    padding-left: 22px;
    padding-top: 12px;
    width: auto;
    font-size: 15px;
  }
}
.store_info .store_info--list-wrap .store_info--list .store_info--item .store_info--sns {
  display: flex;
  column-gap: 10px;
}
.store_info .store_info--banner {
  display: block;
  width: 100%;
  max-width: 790px;
  margin: 68px auto 0;
  box-shadow: 0px 0px 19px -8px #6f7579;
  overflow: hidden;
}
.store_info a:hover {
  opacity: 0.7;
  transition: opacity 0.3s ease-in-out;
}

.movie {
  margin: 80px 0;
}
.movie .movie_inner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  max-width: 800px;
}
@media screen and (max-width: 640px) {
  .movie .movie_inner {
    grid-template-columns: 1fr;
    gap: 30px;
  }
}
.movie .movie_inner .movie_title {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.movie .movie_inner .movie_title h2 {
  font-family: tornac, sans-serif;
  font-size: 5rem;
}
@media screen and (max-width: 640px) {
  .movie .movie_inner .movie_title h2 {
    font-size: 3rem;
  }
}
.movie .movie_inner .movie_title p {
  margin-top: 25px;
  font-size: 2rem;
  text-align: center;
  line-height: 1.8;
  font-size: 1.8rem;
}
.movie .movie_inner .movie_main video {
  width: 100%;
  height: auto;
}

.banner_card_inner {
  margin-top: 30px;
}
.banner_card_inner a:hover {
  opacity: 0.7;
}

.online-price p._source {
  text-decoration: none;
}
.online-price p._source .--price {
  text-decoration: line-through;
}
.online-price p._source .online-tax {
  text-decoration: line-through;
}
.online-price .saleoff {
  color: #f00;
}

.product-main__price._sale .--line-through {
  text-decoration: line-through;
}
.product-main__price._sale .in-tax.--line-through {
  text-decoration: line-through;
}

.top-banner > a {
  transition: 0.3s;
}
.top-banner > a:hover {
  opacity: 0.7;
}

.menu-online {
  position: relative;
}
.menu-online .boximg {
  position: absolute;
  right: 20px;
  top: 30px;
  width: 200px;
  height: auto;
  border-radius: 15px;
  rotate: 15deg;
}
@media screen and (max-width: 640px) {
  .menu-online .boximg {
    right: 10px;
    top: 10px;
    width: 130px;
  }
}

.online-box_item {
  padding-top: 10px !important;
  padding-bottom: 20px !important;
}

.online-box_item.sbox {
  background-color: rgba(250, 191, 0, 0.2);
  border-radius: 5px;
}

.page-vendingMachine {
  font-family: 游ゴシック体, YuGothic, 游ゴシック Medium, Yu Gothic Medium, 游ゴシック, Yu Gothic, sans-serif;
  font-size: 1.6rem;
  color: #073190;
  font-weight: 500;
  line-height: 1.65;
}
@media screen and (max-width: 720px) {
  .page-vendingMachine {
    font-size: 1.4rem;
  }
}
.page-vendingMachine .kvContainer {
  position: relative;
}
.page-vendingMachine .kvContainer__titlewrap {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 4px 4px 0px 0px #073190;
  width: 588px;
  height: 13vw;
  max-height: 180px;
}
@media screen and (max-width: 1000px) {
  .page-vendingMachine .kvContainer__titlewrap {
    height: 120px;
  }
}
@media screen and (max-width: 799px) {
  .page-vendingMachine .kvContainer__titlewrap {
    height: 160px;
  }
}
@media screen and (max-width: 720px) {
  .page-vendingMachine .kvContainer__titlewrap {
    width: 335px;
    top: 95%;
  }
}
.page-vendingMachine .kvContainer__titlewrap .pc-none {
  display: none;
}
@media screen and (max-width: 799px) {
  .page-vendingMachine .kvContainer__titlewrap .pc-none {
    display: inline;
  }
}
@media screen and (max-width: 799px) {
  .page-vendingMachine .kvContainer__titlewrap .sp-none {
    display: none;
  }
}
.page-vendingMachine .kvContainer__title {
  font-size: 2.6rem;
  font-weight: bold;
  text-align: center;
}
@media screen and (max-width: 1000px) {
  .page-vendingMachine .kvContainer__title {
    font-size: 2.2rem;
  }
}
.page-vendingMachine .kvContainer__title--en {
  font-size: 3rem;
  font-weight: normal;
  display: block;
  font-family: Dancing Script, cursive;
}
@media screen and (max-width: 1000px) {
  .page-vendingMachine .kvContainer__title--en {
    font-size: 1.9rem;
  }
}
.page-vendingMachine .bg-container {
  padding-top: 90px;
}
@media screen and (max-width: 1000px) {
  .page-vendingMachine .bg-container {
    padding-top: 160px;
  }
}
.page-vendingMachine .instructionsText {
  color: #fff;
  text-align: center;
  letter-spacing: 0.15em;
  padding-top: 69px;
}
@media screen and (max-width: 720px) {
  .page-vendingMachine .instructionsText {
    padding-top: 80px;
    line-height: 1.85;
  }
}
.page-vendingMachine .mapContainer {
  background-color: #fff;
  border-radius: 20px;
  padding: 70px 50px 106px;
  margin-top: 80px;
  margin-bottom: 100px;
}
@media screen and (max-width: 720px) {
  .page-vendingMachine .mapContainer {
    border-radius: 12px;
    padding: 40px 16px 46px;
    margin-top: 35px;
    margin-bottom: 15px;
  }
}
.page-vendingMachine .mapContainer__title {
  font-size: 2.6rem;
  font-weight: bold;
  max-width: 700px;
  margin: 0 auto;
  padding: 9px 0;
  background-color: #fff;
  border: 1px solid #073190;
  box-shadow: 4px 4px 0px 0px #073190;
  text-align: center;
}
@media screen and (max-width: 720px) {
  .page-vendingMachine .mapContainer__title {
    font-size: 1.8rem;
    box-shadow: 2px 2px 0px 0px #073190;
    padding: 15px 0;
  }
}
.page-vendingMachine .mapLinks__lists {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 468px;
  margin: 0 auto;
  padding-top: 62px;
}
@media screen and (max-width: 720px) {
  .page-vendingMachine .mapLinks__lists {
    padding-top: 30px;
  }
}
.page-vendingMachine .mapLinks__link {
  position: relative;
  padding: 0 8px 6px;
  transition: opacity 0.3s;
  font-weight: bold;
}
@media screen and (max-width: 720px) {
  .page-vendingMachine .mapLinks__link {
    padding: 0 5px 6px;
  }
}
.page-vendingMachine .mapLinks__link:hover {
  opacity: 0.7;
}
.page-vendingMachine .mapLinks__link::before, .page-vendingMachine .mapLinks__link::after {
  content: "";
  display: block;
  background-color: #073190;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.page-vendingMachine .mapLinks__link::before {
  width: 10px;
  height: 5px;
  clip-path: polygon(0 0, 50% 100%, 100% 0);
  top: 100%;
}
.page-vendingMachine .mapLinks__link::after {
  width: 100%;
  height: 1px;
  bottom: 0;
}
.page-vendingMachine .mapListsWrap__lists {
  margin-top: -13px;
}
@media screen and (max-width: 800px) {
  .page-vendingMachine .mapListsWrap__lists {
    margin-top: -16px;
  }
}
.page-vendingMachine .mapListsWrap__item {
  display: flex;
  justify-content: space-between;
  padding-top: 98px;
}
@media screen and (max-width: 800px) {
  .page-vendingMachine .mapListsWrap__item {
    flex-direction: column;
    padding-top: 64px;
  }
}
.page-vendingMachine .mapListsWrap .contentsBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 27.7777777778%;
}
@media screen and (max-width: 800px) {
  .page-vendingMachine .mapListsWrap .contentsBox {
    width: 100%;
  }
}
.page-vendingMachine .mapListsWrap .contentsBox__textWrap {
  letter-spacing: normal;
}
.page-vendingMachine .mapListsWrap .contentsBox__title {
  font-size: 2rem;
  font-weight: bold;
  padding: 12px 0 10px;
  border-top: 1px dotted #073190;
  border-bottom: 1px dotted #073190;
}
@media screen and (max-width: 720px) {
  .page-vendingMachine .mapListsWrap .contentsBox__title {
    font-size: 1.6rem;
    padding: 8px 0;
  }
}
.page-vendingMachine .mapListsWrap .contentsBox__text {
  font-size: 1.5rem;
  padding: 12px 0;
}
.page-vendingMachine .mapListsWrap .contentsBox__text_alert {
  margin-top: 20px;
  font-size: 1.8rem;
  font-weight: bold;
  color: red;
}
.page-vendingMachine .mapListsWrap .googleMapWrap {
  width: 66.6666666667%;
}
@media screen and (max-width: 800px) {
  .page-vendingMachine .mapListsWrap .googleMapWrap {
    width: 100%;
    padding-top: 16px;
  }
}
.page-vendingMachine .mapListsWrap .googleMapWrap__inner {
  position: relative;
  width: 100%;
  padding-top: 58.3333333333%;
}
@media screen and (max-width: 800px) {
  .page-vendingMachine .mapListsWrap .googleMapWrap__inner {
    padding-top: 58.2236842105%;
  }
}
.page-vendingMachine .mapListsWrap .googleMapWrap .googleMap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.contact .heading-blue--contact {
  line-height: 1.3;
}