@use '../base/vars'as *;

.popup {
    background-color: #FABF1C;
    position: fixed;
    z-index: 99;
    writing-mode: vertical-rl;
    font-size: 16px;
    font-weight: bold;
    color: #073191;
    padding: 5px;
    
    @include media_query(760) {
        width: 100%;
        font-size: 13px;
        writing-mode: horizontal-tb;
    }
    
    span{
        display: flex;
        padding: 10px;
        border: dashed 1px #fff;
        line-height: 1;
        letter-spacing: .1em;
        
        @include media_query(760) {
            display: grid;
            grid-template-columns: 1fr 20px;
            align-items: center;
            padding: 5px 10px;
        }
        
        i{
            writing-mode:horizontal-tb;
            width: 18px;
            height: 18px;
            display: grid;
            place-items: center;
            background-color: #fff;
            border-radius: 100%;
            margin-bottom: 5px;
            
            @include media_query(760) {
                margin-top: 5px;
                order: 1;
            }
            
            svg{
                margin-right: -2px;
            }
            
            .svg-icon {
                width: 15px;
                height: 15px;
            }
            
            .svg-icon path,
            .svg-icon polygon,
            .svg-icon rect {
            fill: #000;
            }
        }
    }
    
    // // aspect-ratio: 1 / 1;
    // width: 100%;
    // max-width: 200px;
    // transform: translateY(100%);
    // transition: transform .5s ease-in-out;
    bottom: 0;
    right: 0;

    // @include media_query(760) {
    //     aspect-ratio: calc(375/69);
    //     max-width: 100%;
    // }

    // &.show {
    //     z-index: 100;
    //     transform: translateY(0);
    // }

    // >a {
    //     display: block;
    //     width: 100%;
    //     transition: 0.3s;

    //     &:hover {
    //         opacity: 0.7;
    //     }
    // }
}

.footer {
    @include media_query(760) {
        padding-bottom: percentage(109/407);
    }
}
